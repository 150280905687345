<template>
  <div class="page-container">
    <div class="banner-content">
      <div class="banner">
        <!--<img src="../../assets/banner/chanpin_bg_banner.min.jpg" alt>-->
        <img :src="bannerUrl" alt>
      </div>
      <div class="page-title-content">
        <div class="page-title">
          <div class="first-title">产品介绍</div>
          <div class="two-title">我们只生产PE重包装</div>
        </div>
      </div>
    </div>

    <div class="page-content-div">
      <div class="page-content">
        <div class="product-content">
          <div class="product-item">
            <div class="product-item-left">
              <div class="product-img-content">
                <img src="../../assets/image/one.png" alt>
              </div>
              <router-link to="/application-area?type=1">
                <div class="jump-lingyu">
                  <span>应用领域</span>
                  <i class="el-icon-arrow-right"></i>
                </div>
              </router-link>
            </div>
            <div class="product-item-right">
              <p class="title">PE重包装袋(FFS袋)</p>
              <div
                class="detail"
              >PE重包装袋为FFS袋的统称，为制（Film)—装料（Fill)—封口 (Seal) 一体化的缩写，是欧洲最新发展起来的一种高速包装技术，包装效 率高且成本低，是包装行业中的新兴产品。相比塑编袋，其原料配比独特， 性能优越环保，便于回收利用，符合国际绿色环保包装要求</div>
              <div class="special">特点：</div>
              <div class="special-item">
                <div>1、能承受70～80℃高温灌装和数十次搬运周转；</div>
                <div>2、袋面平整、光洁、美观，图案内容丰富，色彩清晰，并且具有防伪性；</div>
                <div>3、密封性、防潮性、防水性和防污性能优越，可露天堆放，便于运输和储存，内在产品不融化、不结块、不失量、品质不流失；</div>
                <div>4、包装袋配方特殊，遇高温不软化变形，遇严寒不脆断破裂；抗紫外线，色泽稳定；与塑编袋等相比，可使外包装及内在产品的储存、保质期延长一倍时间；5、装包容量灵活，可针对不同商家对色泽、厚度、规格的不同要求设计制作，能适合不同厂家及不同区域的需求；</div>
                <div>5、提高包装效率，降低劳动强度。由于采用自动化包装形式，包装速度比传统编织袋包装提高效率3～5倍，操作工人数量减少75%左右；</div>
                <div>6、包装袋易于回收处理，保护生态；</div>
                <div>7、随着新型材料的应用，薄膜厚度逐渐降低，强度逐渐增强，综合包装成本优于传统包装形式。</div>
              </div>
              <div class="fangan-content">
                <!--<router-link to="/application-area/solution">
                  <div class="fangan">
                    <span>解决方案</span>
                    <i class="el-icon-arrow-right"></i>
                  </div>
                </router-link>-->
                <template v-for="(item, index) in showArray[1].prodSolutions">
                  <router-link
                          :to="{path:'/application-area/solution', query: {activeType: activeType,
                               packageType: 1,
                               fangAnIndex: index,
                               prodId: showArray[1].prodId}}"
                  >
                    <div class="fangan">
                      <span>解决方案 <span v-if="showArray[1].prodSolutions.length>1">{{index + 1}}</span></span>
                      <img
                              class="right-img"
                              src="../../assets/svg/guanwang_icon_jiantou_blue.svg"
                              alt
                      >
                    </div>
                  </router-link>
                </template>
                <router-link :to="{path: '/customized', query: {packageType: 1, areaType: 1}}">
                  <div class="dingzhi">
                    <span>立即定制</span>
                    <i class="el-icon-arrow-right"></i>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
          <div class="product-item">
            <div class="product-item-left">
              <div class="product-img-content">
                <img src="../../assets/image/two.png" alt>
              </div>
              <router-link to="/application-area?type=2">
                <div class="jump-lingyu">
                  <span>应用领域</span>
                  <i class="el-icon-arrow-right"></i>
                </div>
              </router-link>
            </div>
            <div class="product-item-right">
              <p class="title">PE阀口袋</p>
              <div
                class="detail"
              >PE阀口袋，俗称糊底袋，为目前国际流行包装 袋，从顶部或底部阀口进料，采用专门灌装设备，灌 装物料后装成方体，堆包整齐，美观，属于环保袋</div>
              <div class="special">特点：</div>
              <div class="special-item">
                <div>阀口袋具有提高包装效率、方便运输、牢固度强、破损 率低等特点。相对于普通袋，在化工粒子及粉料，以及农业产品如淀粉和 肥料的塑料薄膜包装应用上，具有一系列后者不可比拟的优势。</div>
                <div class="mt20">例如：</div>
                <div>1、方底阀口袋因其形状和高质量的印刷从而具有美观的外型；</div>
                <div>2、方底阀口袋具有更高的强度并且能够承受剧烈的碰撞和处理，因此 由于包装破裂而造成的损失将会大大减少；</div>
              </div>
              <div class="fangan-content">
                <!--<router-link to="/application-area/solution">
                  <div class="fangan">
                    <span>解决方案</span>
                    <i class="el-icon-arrow-right"></i>
                  </div>
                </router-link>-->
                <template v-for="(item, index) in showArray[2].prodSolutions">
                  <router-link
                          :to="{path:'/application-area/solution', query: {activeType: activeType,
                              packageType: 2,
                              fangAnIndex: index,
                              prodId: showArray[2].prodId}}"
                  >
                    <div class="fangan">
                      <span>解决方案 <span v-if="showArray[2].prodSolutions.length>1">{{index + 1}}</span></span>
                      <img
                              class="right-img"
                              src="../../assets/svg/guanwang_icon_jiantou_blue.svg"
                              alt
                      >
                    </div>
                  </router-link>

                </template>

                <router-link :to="{path: '/customized', query: {packageType: 1, areaType: 2}}">
                  <div class="dingzhi">
                    <span>立即定制</span>
                    <i class="el-icon-arrow-right"></i>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
    data() {
        return {
            bannerUrl: '',
            prodObj: {},
            activeType: 1,
            showArray: {
                1: [],
                2: []
            }
        }
    },
    created() {
        const banners = JSON.parse(localStorage.getItem('banners'));
        banners ? this.bannerUrl = banners[2]['imgUrl'] : this.bannerUrl = this.$url.backImg[2];
        this.getList();
    },
    methods: {
        getList() {
            const _this = this;
            let url = this.$url.getBackendApiUrl(`/api/prod/list`);
            url = this.$url.addQueryString(url, "pageNum", 1);
            url = this.$url.addQueryString(url, "pageSize", 99);

            this.$http.post(url, {}).then(
                res => {
                    if (res.data.code == 200) {
                        res.data.data.list.forEach(item => {
                            if (_this.prodObj[item.prodFunction]) {
                                _this.prodObj[item.prodFunction][item.prodType * 1] = item;
                            } else {
                                _this.prodObj[item.prodFunction] = {};
                                _this.prodObj[item.prodFunction][item.prodType * 1] = item;
                            }
                        });
                        _this.showArray = _this.prodObj[_this.activeType]
                    }
                },
                () => {
                }
            );
        },
    }
    }
</script>

<style lang="scss" scoped>
.page-content-div {
  display: flex;
  justify-content: center;
  width: 100%;
  .page-content {
    width: 1200px;
    min-height: 400px;
  }
}
.product-content {
  .product-item {
    display: flex;
    margin-top: 50px;
    margin-bottom: 20px;
    .product-item-left {
      font-family: PingFangSC-Regular;
      width: 300px;
      .product-img-content {
        width: 300px;
        height: 300px;
        text-align: center;
        border: 1px solid rgba(233, 233, 238, 1);
        background: url("../../assets/shuiyin/fangan.png") no-repeat;
        background-position: 150px 145px;
        background-size: 80%;
        img {
          height: 90%;
          width: 90%;
          margin-top: 5%;
          object-fit: contain;
          object-position: center;
        }
      }
      .jump-lingyu {
        height: 44px;
        border: 1px solid rgba(233, 233, 238, 1);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        box-sizing: border-box;
        margin-top: 24px;
        cursor: pointer;
        &:hover {
          border-color: #393fe3;
          color: #393fe3;
        }
      }
    }
    .product-item-right {
      flex: 1;
      margin-left: 40px;
      .title {
        color: rgba(5, 6, 16, 100);
        font-size: 24px;
        font-family: PingFangSC-Semibold;
      }
      .detail {
        line-height: 28px;
        color: rgba(5, 6, 16, 100);
        font-size: 16px;
        margin-top: 20px;
        font-family: PingFangSC-Regular;
      }
      .special {
        color: rgba(5, 6, 16, 100);
        font-size: 16px;
        margin-top: 20px;
        font-family: PingFangSC-Semibold;
      }
      .special-item {
        line-height: 28px;
        color: rgba(114, 114, 122, 100);
        font-size: 16px;
        margin-top: 6px;
        font-family: PingFangSC-Regular;
      }
      .fangan-content {
        display: flex;
        margin-top: 24px;
        font-family: PingFangSC-Regular;
        .fangan {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid rgba(233, 233, 238, 1);
          height: 44px;
          padding: 0 20px;
          color: rgba(57, 63, 227, 100);
          font-size: 16px;
          margin-right: 10px;
          &:hover {
            border-color: rgba(57, 63, 227, 1);
          }
        }
        .dingzhi {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid rgba(233, 233, 238, 1);
          height: 44px;
          color: #fff;
          font-size: 16px;
          background: #393fe3;
          padding-left: 20px;
          padding-right: 8px;
          position: relative;
          &::before {
            position: absolute;
            content: "";
            right: -16px;
            top: 0;
            width: 0;
            height: 0;
            border: 22px solid;
            border-width: 22px 0 22px 16px;
            border-color: transparent transparent transparent #393fe3;
          }
        }
      }
    }
  }
}
.mt25 {
  margin-top: 20px;
}
.ml5 {
  margin-left: 5px;
}
</style>